import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';


function Navbar() {
    return (
        <div class="div-3">
            <div class="div-4">
                <div class="div-5">
                    BFG
                    <span style={{ fontWeight: 400 }}>WORLDWIDE</span>
                </div>
                <div class="div-6">
                    <Link className="div-7" to="/">Home</Link>
                    <Link className="div-8" to="/aboutus">About Us</Link>
                    <Link className="div-9" to="/visionandvalue">Vision & Value</Link>
                    <Link className="div-10" to="/contactus">Contact Us</Link>
                </div>
            </div>
        </div>
    );
}

export default Navbar;







