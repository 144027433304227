import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import Home from './Home';
import AboutUs from './AboutUs';
import VisionAndValue from './VisionAndValue';
import ContactUs from './ContactUs';
import './App.css';

function App() {
  return (
    <Router>
          <div class="container">
            <div class="div">
              <Navbar />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/visionandvalue" element={<VisionAndValue />} />
                <Route path="/contactus" element={<ContactUs />} />
              </Routes>
              <Footer />
            </div>
          </div>
    </Router>
  );
}

export default App;