import React from 'react';
import './GetInTouch.css';
import { Link } from 'react-router-dom';

function GetInTouch() {
    return (
        <div class="getintouch-div">
            <div class="getintouch-link-div">
                <Link to="/contactus">
                    <img loading="lazy"
                        src={require('../assets/images/GetInTouch@2x.png')} 
                        alt="" 
                        class="getintouch-img" />
                </Link>
            </div>
        </div>

    );
}

export default GetInTouch;