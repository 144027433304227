import React from 'react';
import './AboutUs.css';
import GetInTouch from '../GetInTouch';

function AboutUs() {
    return (
        <div class="div-2" id="aboutus">
            <div class="div-11">
                <div class="div-12">
                    <div class="div-13">
                        <img loading="lazy"
                            src={require('../assets/images/BFGlogo@2x.png')}
                            alt="" 
                            class="img" />
                    </div>
                    <div class="div-14">ABOUT US</div>
                </div>
            </div>
            <div class="div-15">
                <div class="div-16">
                    <div class="div-17">
                        <div class="column">
                            <div class="div-18">
                                <div class="div-19">
                                    <div class="div-20">Our Marketing Team</div>
                                    <div class="div-21">
                                        carry exemplary customer services goes
                                        above and beyond the norm,
                                        demonstrating exceptional skill,
                                        attentiveness and problem-solving
                                        abilities.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column-2">
                            <div class="div-22">
                                <div class="div-23">
                                    <div class="div-24">Our Supply Chain</div>
                                    <div class="div-25">
                                        with robust process to track and trace
                                        sources of material to meet client’s
                                        compliance, built to with standard
                                        challenges and deliver consistently high
                                        performance.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="div-26">
                    <div class="div-27">
                        <div class="column">
                            <div class="div-28">
                                <div class="div-29">
                                    <div class="div-30">
                                        <div class="div-31">Our Production Team</div>
                                        <div class="div-32">
                                            withhold granular details provide a
                                            comprehensive, in-depth understanding of
                                            the product specifications.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="column-3">
                            <div class="div-33">
                                <div class="div-34">
                                    <div class="div-35">
                                        <div class="div-36">Our QA Compliance team</div>
                                        <div class="div-37">
                                            take rigorous quality control approach to
                                            ensuring products meet stringent quality
                                            criteria.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="div-38">
                <div class="div-39">
                    <div class="div-40">
                        <div class="div-41">
                            <div class="column-4">
                                <div class="div-42">
                                    <img loading="lazy"
                                        src={require('../assets/images/CARE_LABEL@2x.png')}
                                        alt="" 
                                        class="img-2" />
                                    <div class="div-43">Care Label</div>
                                </div>
                            </div>
                            <div class="column-5">
                                <div class="div-44">
                                    <img loading="lazy"
                                        src={require('../assets/images/RFID@2x.png')}
                                        alt="" 
                                        class="img-3" />
                                    <div class="div-45">RFID</div>
                                </div>
                            </div>
                            <div class="column-6">
                                <div class="div-46">
                                    <img loading="lazy"
                                        src={require('../assets/images/Heat_Transfer@2x.png')}
                                        alt="" 
                                        class="img-4" />
                                    <div class="div-47">Heat Transfer</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="div-48">
                        <div class="div-49">
                            <div class="column-7">
                                <div class="div-50">
                                    <img loading="lazy"
                                        src={require('../assets/images/PAPER_PRODUCT@2x.png')}
                                        alt="" 
                                        class="img-5" />
                                    <div class="div-51">Paper Product</div>
                                </div>
                            </div>
                            <div class="column-8">
                                <div class="div-52">
                                    <img loading="lazy"
                                        src={require('../assets/images/WOVEN_LABEL@2x.png')}
                                        alt="" 
                                        class="img-6" />
                                    <div class="div-53">Woven Label</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GetInTouch />
        </div>

    );
}

export default AboutUs;