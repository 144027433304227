import React, { useEffect, useState } from 'react';
import './VisionAndValue.css';
import GetInTouch from '../GetInTouch';

function VisionAndValue() {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 600);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div class="div-2" id="vision">
            <div class="div-11">
                <div class="div-12">
                    <div class="div-13">
                        <img loading="lazy"
                            src={require('../assets/images/BFGlogo@2x.png')}
                            alt=""
                            class="img" />
                    </div>
                    <div class="div-14">Vision & Value</div>
                </div>
            </div>
            <div class="div-15">
                <div class="div-16">
                    <div class="div-17">
                        <img loading="lazy"
                            src={require('../assets/images/2V@2x.png')}
                            alt=""
                            class="img-2" />
                    </div>
                    <div class="div-18">
                        <img loading="lazy"
                            src={require('../assets/images/VISION@2x.png')}
                            alt=""
                            class="img-3" />
                    </div>
                </div>
            </div>
            <div class="div-19">
                <div class="div-20">
                    <div class="div-21">
                        <div class="column">
                            <div class="div-22">
                                <div className="video-container">
                                    {!isMobileView && (
                                        <video className="media-video" autoPlay muted loop>
                                            <source src={require('../assets/video/2V_Sustainability.mp4')} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    )}
                                    <img loading="lazy" className="media-image" src={require('../assets/video/2V_Sustainability_Cover.png')} alt="" />
                                </div>
                                <div class="div-23">Sustainability</div>
                                <div class="div-24">
                                    We recognize the importance of environmental stewardship and
                                    social
                                    responsibility. Our business operations and product development
                                    will be guided by
                                    principles of sustainability, ensuring that our solutions create
                                    long-term value for
                                    our customers and contribute to a more sustainable future.
                                </div>
                            </div>
                        </div>
                        <div class="column-2">
                            <div class="div-25">
                                <div className="video-container">
                                    {!isMobileView && (
                                        <video className="media-video" autoPlay muted loop>
                                            <source src={require('../assets/video/2V_Innovation.mp4')} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    )}
                                    <img loading="lazy" className="media-image" src={require('../assets/video/2V_Innovation_Cover.png')} alt="" />
                                </div>
                                <div class="div-26">Innovation</div>
                                <div class="div-27">
                                    We are committed to continuously
                                    exploring new ideas, technologies, and
                                    approaches that challenge the status
                                    quo and push the boundaries of what's
                                    possible. Our unwavering focus on
                                    innovation will enable us to deliver
                                    groundbreaking products and services
                                    that address the evolving needs of our
                                    customers.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="div-28">
                    <div class="div-29">
                        <div class="column-3">
                            <div class="div-30">
                                <div className="video-container">
                                    {!isMobileView && (
                                        <video className="media-video" autoPlay muted loop>
                                            <source src={require('../assets/video/2V_IndustryLeadership.mp4')} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    )}
                                    <img loading="lazy" className="media-image" src={require('../assets/video/2V_IndustryLeadership_Cover.png')} alt="" />
                                </div>
                                <div class="div-31">Industry Leadership</div>
                                <div class="div-32">
                                    We recognize the importance of
                                    environmental stewardship and social
                                    responsibility. Our business operations
                                    and product development will be guided
                                    by principles of sustainability, ensuring
                                    that our solutions create long-term
                                    value for our customers and contribute
                                    to a more sustainable future.
                                </div>
                            </div>
                        </div>
                        <div class="column-4">
                            <div class="div-33">
                                <div className="video-container">
                                    {!isMobileView && (
                                        <video className="media-video" autoPlay muted loop>
                                            <source src={require('../assets/video/2V_CustomerEmpowerment.mp4')} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    )}
                                    <img loading="lazy" className="media-image" src={require('../assets/video/2V_CustomerEmpowerment_Cover.png')} alt="" />
                                </div>
                                <div class="div-34">Customer Empowerment</div>
                                <div class="div-35">
                                    At the heart of our vision is a deep commitment to empowering
                                    our customers. We
                                    strive to understand their unique challenges and aspirations,
                                    and to provide them
                                    with the tools, knowledge, and support they need to thrive in
                                    their respective
                                    industries.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="div-36">
                <div class="div-37">
                    By consistently executing on this vision, we will
                    build a resilient, future-oriented business that
                    creates lasting value for our customers, employees,
                    partners, and the communities we serve.
                </div>
            </div>
            <div class="div-38">
                <div class="div-39">
                    <div class="div-40">
                        <img loading="lazy"
                            src={require('../assets/images/2V@2x.png')}
                            alt=""
                            class="img-2" />
                    </div>
                    <div class="div-41">
                        <img loading="lazy"
                            src={require('../assets/images/VALUE@2x.png')}
                            alt=""
                            class="img-3" /></div>
                </div>
            </div>
            <img loading="lazy"
                src={require('../assets/images/RFID.png')}
                alt=""
                class="img-9" />
            <div class="div-42">
                <div class="div-43">
                    BFG's team fundamental beliefs that guide its
                    actions and decisions These values shape the
                    organization's culture and serve as a compass.
                </div>
            </div>
            <GetInTouch />
        </div>
    );
}

export default VisionAndValue;