import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div class="div-56">
            <div class="div-57">
                <div class="div-58">NAVIGATION</div>
                <div class="div-59">
                    <Link className="footer-link" to="/">
                        Home</Link>
                    <Link className="footer-link" to="/aboutus">
                        About Us</Link>
                    <Link className="footer-link" to="/visionandvalue">
                        Vision & Value</Link>
                    <Link className="footer-link" to="/contactus">
                        Contact Us</Link>
                </div>
                <div class="div-64">
                    <Link to="https://www.facebook.com/profile.php?id=61562786430593">
                        <img loading="lazy"
                            src={require('../assets/images/Facebook@2x.png')}
                            alt=""
                            class="img-8" />
                    </Link>
                    <Link to="https://www.instagram.com/bfg_worldwide">
                        <img loading="lazy"
                            src={require('../assets/images/Instagram@2x.png')}
                            alt=""
                            class="img-9" />
                    </Link>
                    <Link to="https://www.linkedin.com/company/bfg-worldwide/">
                        <img loading="lazy"
                            src={require('../assets/images/Linkedin@2x.png')}
                            alt=""
                            class="img-10" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Footer;







