import React from 'react';
import './Home.css';
import GetInTouch from '../GetInTouch';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function Home() {
    return (
        <div class="div-2" id="home">
            <div class="div-11">
                <div class="div-12">
                    <div class="div-13">
                        <img loading="lazy"
                            src={require('../assets/images/BFGlogo@2x.png')}
                            alt=""
                            class="img" />
                    </div>
                    <div class="div-14">
                        <div class="div-15">TOP GARMENT ACCESSORIES AND PACKAGING</div>
                        <div class="div-16">SOLUTION PROVIDER AND MANUFACTURERS</div>
                    </div>
                </div>
            </div>
            <div class="div-17">
                <div className="div-18">
                    <div className="div-19">INNOVATIVE PRODUCT DEVELOPMENT</div>
                    <div className="div-20">OPTIMIZATION SUPPLY CHAIN</div>
                    <div className="div-21">IMPECCABLE MANUFACTURING</div>
                </div>
                <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false} dynamicHeight={false}
                    className="carousel">
                    <div>
                        <img loading="lazy" src={require('../assets/slideshow/image-1.jpg')} alt="" />
                    </div>
                    <div>
                        <img loading="lazy" src={require('../assets/slideshow/image-2.jpg')} alt="" />
                    </div>
                    <div>
                        <img loading="lazy" src={require('../assets/slideshow/image-3.jpg')} alt="" />
                    </div>
                    <div>
                        <img loading="lazy" src={require('../assets/slideshow/image-4.jpg')} alt="" />
                    </div>
                    <div>
                        <img loading="lazy" src={require('../assets/slideshow/image-5.jpg')} alt="" />
                    </div>
                    <div>
                        <img loading="lazy" src={require('../assets/slideshow/image-6.jpg')} alt="" />
                    </div>
                </Carousel>
            </div>
            <div class="div-22">
                <div class="div-23">
                    <div class="div-24">
                        <div class="div-25">
                            BFG
                            <span class="div-26" >
                                , we excel in providing top-tier garment
                                <br />
                                accessories and packaging solutions, with a focus on
                                <br />
                                innovative product development and impeccable
                                <br />
                                manufacturing in key locations.
                            </span>
                        </div>
                    </div>
                    <div class="div-27">
                        <img loading="lazy"
                            src={require('../assets/images/Flags@2x.png')}
                            alt=""
                            class="img-2" />
                    </div>
                </div>
            </div>
            <GetInTouch />
        </div>
    );
}

export default Home;