import React from 'react';
import './ContactUs.css';
import { Link } from 'react-router-dom';

function ContactUs() {
    return (
        <div class="div-2" id="contactus">
            <div class="div-11">
                <div class="div-12">
                    <div class="div-13">
                        <img loading="lazy"
                            src={require('../assets/images/BFGlogo@2x.png')}
                            alt="" 
                            class="img" />
                    </div>
                    <div class="div-14">
                        WE’D LOVE TO HEAR FROM YOU
                    </div>
                    <div class="div-15">
                        Feel free to reach out to us with any questions, feedback, or inquiries. Our dedicated team is here to assist you every step of the way.
                    </div>
                </div>
            </div>
            <div class="div-16">
                <img loading="lazy"
                    src={require('../assets/images/projection-equirectangular.png')}
                    alt="" 
                    class="img-2" />
                <div class="div-17">
                    <div class="div-18">
                        <div class="column">
                            <Link className="footer-link" to="mailto:info@bfgworldwide.com">
                                <div class="div-19">
                                    <div class="div-20">
                                        <img loading="lazy"
                                            src={require('../assets/images/email@2x.png')}
                                            alt="" 
                                            class="img-3" />
                                        <div class="div-21">Email</div>
                                        <div class="div-22">
                                            <div class="div-23">Our friendly team is</div>
                                            <div class="div-24">here to help.</div>
                                        </div>
                                        <div class="div-25">info@bfgworldwide.com</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div class="column-2">
                            <a
                                className="footer-link"
                                href="https://maps.app.goo.gl/rfA4wEDvpY16uj7z5"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div class="div-26">
                                    <img loading="lazy"
                                        src={require('../assets/images/office@2x.png')}
                                        alt="" 
                                        class="img-4" />
                                    <div class="div-27">Office</div>
                                    <div class="div-28">Come say hello at our office HQ.</div>
                                    <div class="div-29">
                                        Flat 2, 19/F, Blk B,<br />
                                        Wah Tat Industrial Centre,<br />
                                        Kwai Chung, Hong Kong
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="column-3">
                            <a className="footer-link" href="tel:+85224261830">
                                <div class="div-30">
                                    <img loading="lazy"
                                        src={require('../assets/images/phone@2x.png')}
                                        alt="" 
                                        class="img-5" />
                                    <div class="div-31">Phone</div>
                                    <div class="div-32">Mon-Fri<br />
                                    from 9am to 5pm.</div>
                                    <div class="div-33">+852 2426 1830</div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ContactUs;